<template>
    <component
        :is="noButton ? 'div' : 'button'"
        class="icon-button"
        :class="{
            'icon-button--disabled': disabled,
            'icon-button--filled': type === 'filled',
            'icon-button--labeled': label,
            'icon-button--outlined': type === 'outlined',
        }"
        v-on="$listeners"
    >
        <div class="icon-button__content">
            <div class="icon-button__hover" :style="hoverElementStyle"></div>
            <slot />
        </div>
        <div v-if="label" class="icon-button__label">
            {{ label }}
        </div>
    </component>
</template>

<script>
export default {
    name: 'IconButton',
    props: {
        type: {
            type: String,
            default: null,
        },
        noButton: {
            type: Boolean,
            default: false,
        },
        hoverColor: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hoverElementStyle() {
            return this.hoverColor
                ? { 'background-color': this.hoverColor }
                : {}
        },
    },
}
</script>

<style lang="scss" scoped>
.icon-button {
    $self: &;
    position: relative;
    padding: 0;
    border: none;
    background: transparent;
    -webkit-appearance: none;
    user-select: none;
    cursor: pointer;
    outline: none;

    &__content {
        position: relative;
    }

    &__hover {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-gray-light;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.15s;

        & + * {
            position: relative;
            display: block;
        }
    }

    &__label {
        position: relative;
        margin-top: 7px;
        font-size: $fs-smaller;
        letter-spacing: -0.25px;
        color: $color-primary;
    }

    &--labeled {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }

    &--filled {
        border: 2px solid $color-gray-medium;
        border-radius: $br-input;
        padding: 0.125rem;
        font-size: 20px;
        color: $color-gray-dark;

        &:hover {
            background: #000;
            border: 2px solid #000;
            color: #fff;
        }
    }

    &--outlined {
        padding: 0.125rem;
        border: 1.5px solid #000;
        border-radius: $br-input;

        &:hover {
            background: $color-gray-medium;
            transition: background 0.25s;
        }
    }

    &--filled,
    &--outlined {
        #{$self}__hover {
            display: none;
        }
    }

    &:active {
        color: initial;
    }

    &:hover {
        #{$self}__hover {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1.75);
        }
    }
}
</style>
