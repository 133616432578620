const state = {
    mapInstance: null,
    activeLayer: {},
    savedBounds: null,
    showLabels: false,
    clusteringEnabled: true,
    positionTrackingEnabled: false,
    lastUserPosition: null,
    navigationRoute: null,
    marker: null,
    heatmapData: null,
    markerPosition: {},
    tileProviders: [],
}

const getters = {
    getActiveLayer(state) {
        return state.activeLayer
    },
    getLabelsStatus(state) {
        return state.showLabels
    },
    getHeatmapData(state) {
        return state.heatmapData
    },
}

const actions = {
    centerMapOnTracker({ state, rootState }, id) {
        const tracker = rootState.tracker.trackers.find(item => item.id === id)

        if (tracker) {
            state.mapInstance.fitBounds([
                [
                    tracker.asset_details.position.latitude,
                    tracker.asset_details.position.longitude,
                ],
            ])
        }
    },
}

const mutations = {
    setMapInstance(state, data) {
        state.mapInstance = data
    },
    setActiveLayer(state, data) {
        state.activeLayer = data
    },
    saveBounds(state, data) {
        state.savedBounds = data
    },
    clearSavedBounds(state) {
        state.savedBounds = null
    },
    switchShowLabels(state) {
        state.showLabels = !state.showLabels
    },
    switchClusteringEnabled(state) {
        state.clusteringEnabled = !state.clusteringEnabled
    },
    setPositionTrackingEnabled(state, data) {
        state.positionTrackingEnabled = data
    },
    setUserPosition(state, data) {
        state.lastUserPosition = data
    },
    setNavigationRoute(state, data) {
        state.navigationRoute = data
    },
    setTileProviders(state, data) {
        state.tileProviders = data
    },
    addHeatmapData(state, data) {
        if (!state.heatmapData) {
            state.heatmapData = []
        }

        state.heatmapData.push(...data)
    },
    resetHeatmapData(state) {
        state.heatmapData = null
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
