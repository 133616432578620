import jwt_decode from 'jwt-decode'
import moment from 'moment'

import { userService } from '@/service/user.service'

const navigationUsers = process.env.VUE_APP_NAVIGATION_USERS
    ? JSON.parse(process.env.VUE_APP_NAVIGATION_USERS)
    : []

const jwt = JSON.parse(localStorage.getItem('JWT'))
const jwtOverwritten = JSON.parse(localStorage.getItem('JWT-overwritten'))
const userInfo = JSON.parse(localStorage.getItem('userInfo'))

const state = {
    status: jwt ? { loggedIn: true } : {},
    jwt,
    jwtOverwritten,
    userInfo,
    nextPath: null,
    isSimulationMode: !!jwtOverwritten,
}

const getters = {
    userInfo(state) {
        return state.userInfo
    },
    getJWT(state) {
        return state.jwt
    },
    getCurrentUser(state) {
        return state.jwt?.access ? jwt_decode(state.jwt.access) : null
    },
    getCurrentUserId(state, getters) {
        return getters.getCurrentUser?.user_id
    },
    hasActivityAccess(state) {
        if (!state.jwt?.access || !state.userInfo) {
            return false
        }

        return state.userInfo.platform === 'alplora'
    },
    hasConstructionProjectAccess(state, getters) {
        return !!getters.getCurrentUser?.construction_project_access
    },
    hasDashboardAccess(state, getters) {
        return !!getters.getCurrentUser?.dashboard_access
    },
    hasDebugAccess(state, getters) {
        return !!getters.getCurrentUser?.debug_access
    },
    hasLocationHistoryAccess(state, getters) {
        return !!getters.getCurrentUser?.location_history_access
    },
    hasRulesAccess(state, getters) {
        return !!getters.getCurrentUser?.rule_access
    },
    hasRunningTimeAccess(state, getters) {
        return !!getters.getCurrentUser?.running_time_access
    },
    hasWebhookAccess(state, getters) {
        return !!getters.getCurrentUser?.webhook_access
    },
    isNavigationAvailable(state, getters) {
        return getters.getCurrentUser?.username
            ? navigationUsers.includes(getters.getCurrentUser.username)
            : false
    },
    isAdminOrStaff(state, getters) {
        return (
            getters.getCurrentUser &&
            (getters.getCurrentUser.is_account_admin ||
                getters.getCurrentUser.is_staff)
        )
    },
    isStaff(state) {
        const token = state.jwtOverwritten?.access || state.jwt?.access

        if (!token) {
            return false
        }

        return jwt_decode(state.jwt.access).is_staff
    },
    isSuperuser(state) {
        const token = state.jwtOverwritten?.access || state.jwt?.access

        if (!token) {
            return false
        }

        return jwt_decode(token).is_superuser
    },
    isDemoUser(state, getters) {
        return (
            getters.getCurrentUser?.username ===
            process.env.VUE_APP_DEMO_USERNAME
        )
    },
    batteryThreshold(state) {
        return state.userInfo?.alertsettings?.battery_alert_threshold || 20
    },
    getAlertsettingsEmailRecipients(state) {
        return state.userInfo?.alertsettings?.email_recipients
    },
    getAlertsettingsSMSRecipients(state) {
        return state.userInfo?.alertsettings?.sms_recipients
    },
}

const actions = {
    login({ commit }, { username, password }) {
        commit('loginRequest')

        return userService.login(username, password).then(
            jwt => {
                commit('loginSuccess', jwt)
                return jwt
            },
            error => {
                commit('loginFailure')
                return Promise.reject(error)
            }
        )
    },
    refresh({ commit, state }) {
        return userService
            .refreshAccessToken(state.jwt.refresh)
            .then(jwt => {
                commit('loginSuccess', jwt)
                return jwt
            })
            .catch(() => {
                commit('loginFailure')
                return Promise.reject()
            })
    },
    logout({ commit }) {
        userService.logout()
        commit('logout')
    },
    refreshAccessTokenIfNecessary({ state, dispatch }) {
        return new Promise((resolve, reject) => {
            if (state.jwt && tokenIsValid(state.jwt.access)) {
                resolve(false)
            } else {
                if (tokenIsValid(state.jwt.refresh)) {
                    dispatch('refresh').then(
                        () => resolve(true),
                        () => reject()
                    )
                } else {
                    dispatch('logout')
                    reject()
                }
            }
        })
    },
    async loadUserInfo({ commit, getters }) {
        const userInfo = await userService.fetchUserInfo(
            getters.getCurrentUserId
        )

        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        commit('setUserInfo', userInfo)
    },
    async simulateUser({ commit, state }, id) {
        if (!state.isSimulationMode) {
            const jwtOverwritten = localStorage.getItem('JWT')
            const userInfoOverwritten = localStorage.getItem('userInfo')

            localStorage.setItem('JWT-overwritten', jwtOverwritten)
            localStorage.setItem('userInfo-overwritten', userInfoOverwritten)

            commit('setJwtOverwritten', JSON.parse(jwtOverwritten))
            commit('setSimulationMode', true)
        }

        const { access, refresh } = await userService.fetchUserToken(id)
        localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
        commit('loginSuccess', { access, refresh })

        const userInfo = await userService.fetchUserInfo(id)
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
        commit('setUserInfo', userInfo)
    },
    stopSimulatingUser({ commit }) {
        commit('setSimulationMode', false)
        commit('setJwtOverwritten', null)

        const token = localStorage.getItem('JWT-overwritten')
        const userInfo = localStorage.getItem('userInfo-overwritten')

        if (!token || !userInfo) {
            return
        }

        localStorage.setItem('JWT', token)
        localStorage.setItem('userInfo', userInfo)

        localStorage.removeItem('JWT-overwritten')
        localStorage.removeItem('userInfo-overwritten')

        commit('loginSuccess', JSON.parse(token))
        commit('setUserInfo', JSON.parse(userInfo))
    },
}

const mutations = {
    loginRequest(state) {
        state.status = { loggingIn: true }
        state.jwt = null
    },
    loginSuccess(state, jwt) {
        state.status = { loggedIn: true }
        state.jwt = jwt
    },
    loginFailure(state) {
        state.status = {}
        state.jwt = null
    },
    logout(state) {
        state.status = {}
        state.jwt = null
        state.userInfo = null
    },
    setNextPath(state, nextPath) {
        state.nextPath = nextPath
    },
    setUserInfo(state, data) {
        state.userInfo = data
    },
    setSimulationMode(state, data) {
        state.isSimulationMode = data
    },
    setJwtOverwritten(state, data) {
        state.jwtOverwritten = data
    },
}

function tokenIsValid(token) {
    if (token == null) {
        return false
    }
    let jwt_info = jwt_decode(token)
    let expiresAt = moment.unix(jwt_info.exp)
    return !!expiresAt.isAfter(moment())
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
