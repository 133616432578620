const units = {
    battery: '%',
    battery_voltage: 'V',
    co2: 'ppm',
    distance: 'm',
    fill_level: '%',
    humidity: '%',
    level: 'm',
    mass: 't',
    moisture: '%',
    percentage: '%',
    power: 'W',
    running_time: 'h',
    speed: 'km/h',
    t1: '°C',
    t2: '°C',
    t3: '°C',
    t4: '°C',
    t5: '°C',
    t6: '°C',
    temperature: '°C',
    voc: 'ppb',
    voltage: 'kV',
    voltage_0: 'kV',
    voltage_1: 'kV',
    voltage_2: 'kV',
    voltage_3: 'kV',
    voltage_4: 'kV',
    volume: 'm³',
    water_level: 'mm',
}

const convertToBinLevel = value => {
    const height = 1.2 // meters

    value = (height - value) / height

    if (value < 0) {
        value = 0
    } else if (value > 1) {
        value = 1
    }

    return Math.round(value * 100)
}

const convertToParkingAvailability = value => {
    return value > 1.4
}

const convertToSbbBinLevel = value => {
    if (value < 0.05) {
        value = 0
    } else if (value > 0.72) {
        value = 0.67
    } else {
        value -= 0.05
    }

    return 100 - Math.floor((value / 0.67) * 100)
}

export default {
    convertToBinLevel,
    convertToParkingAvailability,
    convertToSbbBinLevel,
    units,
}
