import { httpHelper } from '@/utils'
import moment from 'moment-timezone'

const LOADING_STATE = {
    INIT: 'init',
    LOADING: 'loading',
    HAS_DATA: 'has_data',
    ERROR: 'error',
}

// initial state
const state = {
    trips: [],
    selectedTripID: null,
    loading_state: LOADING_STATE.INIT,
}

// getters
const getters = {
    getTrips(state) {
        return state.trips
    },
    getSelectedTrip(state) {
        if (state.selectedTripID) {
            return state.trips.find(trip => trip.id === state.selectedTripID)
        }
        return null
    },
    isLoading(state) {
        return state.loading_state === LOADING_STATE.LOADING
    },
    hasData(state) {
        return state.loading_state === LOADING_STATE.HAS_DATA
    },
}

// actions
const actions = {
    async loadTrips({ commit }, { asset_id, from_date, to_date }) {
        commit('setDataLoading')
        commit('setTrips', [])
        let url =
            'measurementseries-full/?' +
            `start=${encodeURIComponent(from_date)}` +
            `&end=${encodeURIComponent(to_date)}` +
            `&asset=${asset_id}` +
            `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`
        let trips = []
        while (url) {
            const { data } = await httpHelper.get(url)
            trips = Object.freeze(trips.concat(data.results))
            url = data.next
        }

        // We need to het the measurement in ascending order (on timestamp), so that
        // on the trip history layer we can look forward and determine the travel direction of the asset.
        for (let trip of trips) {
            if (trip.measurements) {
                trip.measurements.sort((a, b) =>
                    moment(a.timestamp).diff(moment(b.timestamp))
                )
            }
        }

        commit('setTrips', trips)
        commit('setFinishedLoading')
    },
}

// mutations
const mutations = {
    setTrips(state, data) {
        state.trips = data
    },
    setSelectedTripID(state, trip_id) {
        state.selectedTripID = trip_id
    },
    setDataLoading(state) {
        state.loading_state = LOADING_STATE.LOADING
    },
    setFinishedLoading(state) {
        state.loading_state = LOADING_STATE.HAS_DATA
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
