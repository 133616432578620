<template>
    <textarea
        v-if="textarea"
        ref="input"
        v-bind="$attrs"
        class="base-input"
        :class="{
            'base-input--block': block,
        }"
        v-on="inputListeners"
    />

    <label
        v-else
        class="base-input"
        :class="{
            'base-input--block': block,
            'base-input--with-icon': $slots.icon,
            'base-input--with-postfix': $slots.postfix,
        }"
    >
        <slot name="icon" />

        <input ref="input" v-bind="$attrs" v-on="inputListeners" />

        <span v-if="$slots.postfix" class="base-input__postfix">
            <slot name="postfix" />
        </span>
    </label>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
    name: 'BaseInput',
    inheritAttrs: false,
    props: {
        block: {
            type: Boolean,
            default: true,
        },
        textarea: {
            type: Boolean,
            default: false,
        },
        debounce: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        inputListeners() {
            return {
                ...this.$listeners,
                input: this.debounce
                    ? debounce(this.handleInput, this.debounce)
                    : this.handleInput,
            }
        },
    },
    methods: {
        blur() {
            this.$refs.input.blur()
        },
        focus() {
            this.$refs.input.focus()
        },
        handleInput(event) {
            this.$emit('input', event.target.value)
        },
    },
}
</script>

<style lang="scss" scoped>
@mixin input-style {
    padding: 12px;
    width: 100%;
    background-color: $color-gray-lighter-new;
    border: none;
    border-radius: 4px;
    line-height: 16px;
    font-size: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    color: $color-text-new;
    outline: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
    }
}

@mixin input-style-block {
    padding: 10px 12px;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    line-height: 20px;
}

.base-input {
    position: relative;

    svg {
        position: absolute;
        top: 12px;
        left: 12px;
        cursor: text;
    }

    input {
        @include input-style;
    }

    &--block {
        input {
            @include input-style-block;
        }
    }

    &--with-icon {
        input {
            padding-left: 38px;
        }
    }

    &--with-postfix {
        display: flex;

        input {
            padding-right: 4px;
            border-radius: 4px 0 0 4px;
            text-align: right;

            &::placeholder {
                text-align: left;
            }
        }
    }

    &__postfix {
        margin: 0;
        padding: 12px;
        padding-left: 0;
        background-color: $color-gray-lighter-new;
        border-radius: 0 4px 4px 0;
        line-height: 16px;
        font-size: 14px;
        color: $color-text-new;
        cursor: text;
    }
}

textarea {
    &.base-input {
        @include input-style;
        min-width: 100%;
        max-width: 100%;
        min-height: 40px;

        &--block {
            @include input-style-block;
        }
    }
}
</style>
