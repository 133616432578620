<template>
    <div class="map-search">
        <base-input
            ref="input"
            v-model="query"
            :placeholder="$t('searchPlaceholder')"
            @blur="isDropdownVisible = false"
            @focus="isDropdownVisible = true"
            @keydown.up.prevent="handleOptionNavigation(-1)"
            @keydown.down.prevent="handleOptionNavigation(1)"
            @keydown.enter.prevent="handleOptionSelect()"
        >
            <template #icon>
                <search-icon width="16" height="16" color="#acbac7" />
            </template>
        </base-input>

        <transition name="dropdown">
            <div
                v-if="isDropdownVisible"
                class="map-search__dropdown"
                @mousedown.prevent
            >
                <p v-if="!query" class="l-padded">
                    {{ $t('searchHint') }}
                </p>

                <p
                    v-else-if="
                        !filteredAssets.length &&
                            !filteredLocations.length &&
                            !places.length
                    "
                    class="l-padded"
                >
                    {{ $t('searchEmpty') }}
                </p>

                <p v-if="filteredAssetsPortion.length" class="l-padded">
                    {{ $t('shared.assets') }}
                </p>

                <a
                    v-for="(item, i) in filteredAssetsPortion"
                    :key="'asset' + item.id"
                    :class="{ hover: i === hoverIndex }"
                    @click="handleAssetClick(item)"
                >
                    <span>
                        <pin-icon width="16" height="16" />
                    </span>

                    {{ item.asset_details.name }}
                </a>

                <a
                    v-if="
                        filteredAssets.length &&
                            filteredAssets.length > assetsLimit
                    "
                    class="t-small"
                    @click="assetsLimit += limitStep"
                >
                    {{
                        $t('showMoreAssets', {
                            items: $t('shared.assets'),
                        })
                    }}
                </a>

                <p v-if="filteredLocationsPortion.length" class="l-padded">
                    {{ $t('locations') }}
                </p>

                <a
                    v-for="(item, i) in filteredLocationsPortion"
                    :key="'location' + item.id"
                    :class="{
                        hover: filteredAssetsPortion.length + i === hoverIndex,
                    }"
                    @click="handleLocationClick(item)"
                >
                    <span>
                        <pin-location-icon width="16" height="16" />
                    </span>

                    {{ item.name }}
                </a>

                <a
                    v-if="
                        filteredLocations.length &&
                            filteredLocations.length > locationsLimit
                    "
                    class="t-small"
                    @click="locationsLimit += limitStep"
                >
                    {{ $t('showMoreLocations') }}
                </a>

                <p v-if="placesPortion.length" class="l-padded">
                    {{ $t('places') }}
                </p>

                <a
                    v-for="(item, i) in placesPortion"
                    :key="'place' + item.id"
                    :class="{
                        hover:
                            filteredAssetsPortion.length +
                                filteredLocationsPortion.length +
                                i ===
                            hoverIndex,
                    }"
                    @click="handlePlaceClick(item)"
                >
                    <span>
                        <map-icon width="16" height="16" />
                    </span>

                    {{ item.place_name }}
                </a>

                <a
                    v-if="places.length && places.length > placesLimit"
                    class="t-small"
                    @click="placesLimit += limitStep"
                >
                    {{ $t('showMorePlaces') }}
                </a>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

import { httpHelper } from '@/utils'

import BaseInput from '../redesigned/BaseInput'
import MapIcon from '../icons/MapIcon'
import PinIcon from '../icons/PinIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'MapSearch',
    components: {
        BaseInput,
        MapIcon,
        PinIcon,
        PinLocationIcon,
        SearchIcon,
    },
    data() {
        return {
            assetsLimit: 3,
            hoverIndex: null,
            isDropdownVisible: false,
            limitStep: 3,
            locationsLimit: 3,
            places: [],
            placesLimit: 3,
            query: '',
        }
    },
    computed: {
        ...mapState('locations', ['locations']),
        ...mapState('map', ['mapInstance']),
        ...mapState('tracker', ['trackers']),
        combinedPortion() {
            return [
                ...this.filteredAssetsPortion,
                ...this.filteredLocationsPortion,
                ...this.placesPortion,
            ]
        },
        filteredAssets() {
            if (!this.query) {
                return []
            }

            return this.trackers.filter(
                asset =>
                    asset.asset_details.name
                        .toLowerCase()
                        .includes(this.query.toLowerCase()) ||
                    asset.deveui
                        .toLowerCase()
                        .includes(this.query.toLowerCase()) ||
                    asset.asset_details.identification
                        ?.toLowerCase()
                        ?.includes(this.query.toLowerCase())
            )
        },
        filteredAssetsPortion() {
            return this.filteredAssets.slice(0, this.assetsLimit)
        },
        filteredLocations() {
            if (!this.query) {
                return []
            }

            return this.locations.filter(location =>
                location.name.toLowerCase().includes(this.query.toLowerCase())
            )
        },
        filteredLocationsPortion() {
            return this.filteredLocations.slice(0, this.locationsLimit)
        },
        placesPortion() {
            return this.places.slice(0, this.placesLimit)
        },
    },
    watch: {
        query: debounce(async function() {
            this.assetsLimit = this.limitStep
            this.locationsLimit = this.limitStep
            this.placesLimit = this.limitStep
            this.hoverIndex = null

            if (this.query) {
                const { lat, lng } = this.mapInstance.getCenter()
                const { data } = await httpHelper.get(
                    `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.query}.json`,
                    {
                        params: {
                            access_token: process.env.VUE_APP_MAPBOX_TOKEN,
                            proximity: `${lng},${lat}`,
                        },
                    }
                )
                this.places = data.features
            } else {
                this.places = []
            }
        }, 250),
    },
    methods: {
        handleAssetClick({ id }) {
            this.handleReset()
            this.$router.push('/map/assets/' + id)
        },
        handleLocationClick({ id }) {
            this.handleReset()
            this.$router.push('/map/location/' + id)
        },
        handlePlaceClick(place) {
            this.handleReset()

            if (place.bbox) {
                const [lng1, lat1, lng2, lat2] = place.bbox
                this.mapInstance.fitBounds([
                    [lat1, lng1],
                    [lat2, lng2],
                ])
            } else {
                const [lng, lat] = place.center
                this.mapInstance.setView([lat, lng], 16)
            }
        },
        handleOptionNavigation(step) {
            if (!this.combinedPortion.length) {
                this.hoverIndex = null
                return
            }

            if (this.hoverIndex === null) {
                this.hoverIndex = 0
                return
            }

            this.hoverIndex =
                (this.hoverIndex + step + this.combinedPortion.length) %
                this.combinedPortion.length
        },
        handleOptionSelect() {
            const option = this.combinedPortion[this.hoverIndex]
            const assetsLength = this.filteredAssetsPortion.length
            const locationsLength = this.filteredLocationsPortion.length

            if (this.hoverIndex < assetsLength) {
                this.handleAssetClick(option)
            } else if (this.hoverIndex < assetsLength + locationsLength) {
                this.handleLocationClick(option)
            } else {
                this.handlePlaceClick(option)
            }
        },
        handleReset() {
            this.$refs.input.blur()
            this.hoverIndex = null
            this.query = ''
        },
    },
}
</script>

<i18n>
{
    "en": {
        "locations": "Locations",
        "places": "Jump to address",
        "searchEmpty": "Nothing found",
        "searchHint": "Start typing to search",
        "searchPlaceholder": "Search",
        "showMoreAssets": "Show more {items} ...",
        "showMoreLocations": "Show more locations ...",
        "showMorePlaces": "Show more addresses ..."
    },
    "de": {
        "locations": "Standorte",
        "places": "Adressen",
        "searchEmpty": "Nichts gefunden",
        "searchHint": "Beginnen Sie zu tippen, um zu suchen",
        "searchPlaceholder": "Suche",
        "showMoreAssets": "Mehr {items} anzeigen ...",
        "showMoreLocations": "Mehr Standorte anzeigen ...",
        "showMorePlaces": "Mehr Adressen anzeigen ..."
    },
    "it": {
        "locations": "Locazione",
        "places": "Indirizzi",
        "searchEmpty": "Niente di trovato",
        "searchHint": "Iniziare a digitare per cercare",
        "searchPlaceholder": "Cerca",
        "showMoreAssets": "Mostra altri {items} ...",
        "showMoreLocations": "Mostra altri luoghi ...",
        "showMorePlaces": "Mostra altri indirizzi ..."
    }
}
</i18n>

<style lang="scss" scoped>
.map-search {
    position: relative;
    display: flex;
    width: 100%;

    .base-input {
        width: 100%;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        margin: 6px 0 12px;
        padding: 8px 0;
        width: 100%;
        max-height: 60vh;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        text-align: left;
        overflow: auto;
        z-index: 1000;

        a {
            display: flex;
            padding: 12px 20px;
            transition: background-color 0.1s;
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter-new;
            }

            &.hover {
                background-color: $color-gray-light-new;
            }

            svg {
                margin-right: 8px;
                margin-bottom: -3px;
            }
        }

        p {
            text-align: center;
            color: $color-gray-dark-new;
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}
</style>
