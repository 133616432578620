// This are global translations. All other translations are made in the components!
export default {
    en: {
        shared: {
            measurementOptions: {
                battery_status: {
                    critical: 'Critical',
                    ok: 'Ok',
                    warning: 'Warning',
                },
            },
            measurements: {
                ai1: 'Analog input - channel 1',
                battery: 'Battery',
                battery_status: 'Battery status',
                battery_voltage: 'Battery voltage',
                co2: 'CO2',
                custom: 'Custom',
                d1: 'State - Channel 1',
                d2: 'State - Channel 2',
                d3: 'State - Channel 3',
                d4: 'State - Channel 4',
                d5: 'State - Channel 5',
                d6: 'State - Channel 6',
                distance: 'Distance',
                fill_level: 'Fill level',
                geofence: 'Geofence',
                humidity: 'Humidity',
                level: 'Level',
                lower_loop: 'Lower loop',
                mass: 'Mass',
                moisture: 'Moisture',
                offline: 'Offline',
                potential: 'Potential',
                power: 'Power',
                probe_state: 'Probe State',
                regex: 'Regex',
                running_time: 'Operating time',
                speed: 'Speed',
                state: 'State',
                t0: 'Temperature - Channel 0',
                t1: 'Temperature - Channel 1',
                t2: 'Temperature - Channel 2',
                t3: 'Temperature - Channel 3',
                t4: 'Temperature - Channel 4',
                t5: 'Temperature - Channel 5',
                t6: 'Temperature - Channel 6',
                tau: 'Tau',
                temperature: 'Temperature',
                upper_loop: 'Upper loop',
                voc: 'VOC',
                voltage: 'Voltage Fence',
                voltage_0: 'Wire 1',
                voltage_1: 'Wire 2',
                voltage_2: 'Wire 3',
                voltage_3: 'Wire 4',
                voltage_4: 'Wire 5',
                volume: 'Volume',
                water_level: 'Water level',
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Compressor trailer',
                'default-gasser': 'Various',
                'material-container': 'Material container',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu': 'SBB recycling station aluminium',
                'sbb-bin-paper': 'SBB recycling station paper',
                'sbb-bin-pet': 'SBB recycling station PET',
                'sbb-bin-waste': 'SBB recycling station waste',
                'skip-bin': 'Skip bin',
                'team-container': 'Team container',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Waste dump',
                'wheel-loader': 'Wheel Loader',
                Traktor: 'Tractor',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Car',
                compressor: 'Compressor',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Roller',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Cancel',
            lastMeasurement: 'Last measurement',
            no: 'No',
            noOptions: 'No options available',
            noSearchResult: 'No search result',
            save: 'Save',
            yes: 'Yes',
        },
        router: {
            accel_y: 'Collection',
            statuschartview: 'State',
            allAssets: 'All Assets',
            animalactivitychart: 'Activity',
            assetConnectionHeatmap: 'Connection Heatmap',
            assetLocationHistoryChart: 'Location History',
            assetScheme: 'Schema',
            battery: 'Battery',
            battery_percentage: 'State of Charge',
            battery_voltage: 'Battery voltage',
            changePassword: 'Change Password',
            chartsCombined: 'Combined chart',
            charts: 'Charts',
            co2: 'Carbon dioxide',
            connectionHeatmap: 'Connection Heatmap',
            cm2humiditychart: 'Temperature and humidity',
            createlocation: 'New Location',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            dashboardSettings: 'Settings',
            detail: 'Details',
            distance: 'Distance',
            dynamicbinchart: 'Fill Level',
            ecocoach_d1_count: 'Power up',
            ecocoach_temperature_0: 'Battery Temperature',
            ecocoach_temperature_1: 'AFE Temperature',
            ecocoach_voltage_0: 'Battery Voltage',
            editAsset: 'Edit Asset',
            editlocation: 'Edit Location',
            fenceGuardVoltage: 'Voltage Fence',
            fenceguardstatuschart: 'Voltage Fence',
            fillLevel: 'Fill level',
            fill_level: 'Fill level',
            globalAnimalActivityChart: 'Activity',
            humidity: 'Humidity',
            level: 'Level',
            location: 'Location',
            locationAnimalActivityChart: 'Activity',
            locationConnectionHeatmap: 'Connection Heatmap',
            locationDetailAssetDetail: 'Details',
            locationHistoryChart: 'Location History',
            locationhistory: 'Location history',
            triphistory: 'Trip History',
            lostAssets: 'Left zone',
            lowBatteryAssets: 'Low battery',
            lower_loop: 'Lower loop',
            mass: 'Mass',
            menu: 'Menu',
            navigation: 'Navigation',
            networkChart: 'Signal Quality',
            newAsset: 'New Tracker',
            notifications: 'Notifications',
            potential: 'Potential',
            power: 'Total',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            runningTimeChart: 'Running Time',
            running_time: 'Running Time',
            sbbbinchart: 'Fill level',
            search: 'Search',
            settings: 'Settings',
            signal: 'Signal',
            speed: 'Speed',
            t1: 'Inlet temperature',
            t2: 'Outlet temperature',
            t3: 'Room temperature',
            t4: 'Outside temperature',
            t5: 't5',
            t6: 't6',
            tau: 'Tau',
            temperature: 'Temperature',
            tracking: 'Tracking',
            upper_loop: 'Upper loop',
            voc: 'Volatile organic compounds',
            voltage_0: 'Wire 1',
            voltage_1: 'Wire 2',
            voltage_2: 'Wire 3',
            voltage_3: 'Wire 4',
            voltage_4: 'Wire 5',
            volume: 'Volume',
            water_level: 'Water level',
            moisture: 'Moisture',
            treehistorymoisturechart: 'Moisture',
            treehistorytemperaturechart: 'Temperature',
        },
    },
    de: {
        shared: {
            measurementOptions: {
                battery_status: {
                    critical: 'Critical',
                    ok: 'Ok',
                    warning: 'Warning',
                },
            },
            measurements: {
                ai1: 'Analogeingang - Kanal 1',
                battery: 'Batterie',
                battery_status: 'Battery status',
                battery_voltage: 'Batteriespannung',
                co2: 'CO2',
                custom: 'Benutzerdefiniert',
                d1: 'Zustand - Kanal 1',
                d2: 'Zustand - Kanal 2',
                d3: 'Zustand - Kanal 3',
                d4: 'Zustand - Kanal 4',
                d5: 'Zustand - Kanal 5',
                d6: 'Zustand - Kanal 6',
                distance: 'Distanz',
                fill_level: 'Füllstand',
                geofence: 'Geofence',
                humidity: 'Luftfeuchtigkeit',
                level: 'Pegel',
                lower_loop: 'Untere Schlaufe',
                mass: 'Masse',
                moisture: 'Feuchtigkeit',
                offline: 'Offline',
                potential: 'Potential',
                power: 'Leistung',
                probe_state: 'Sondenstatus',
                regex: 'Regex',
                running_time: 'Betriebszeit',
                speed: 'Geschwindigkeit',
                state: 'Zustand',
                t0: 'Temperatur - Kanal 0',
                t1: 'Temperatur - Kanal 1',
                t2: 'Temperatur - Kanal 2',
                t3: 'Temperatur - Kanal 3',
                t4: 'Temperatur - Kanal 4',
                t5: 'Temperatur - Kanal 5',
                t6: 'Temperatur - Kanal 6',
                tau: 'Tau',
                temperature: 'Temperatur',
                upper_loop: 'Obere Schlaufe',
                voc: 'VOC',
                voltage: 'Spannung Weidezaun',
                voltage_0: 'Litze 1',
                voltage_1: 'Litze 2',
                voltage_2: 'Litze 3',
                voltage_3: 'Litze 4',
                voltage_4: 'Litze 5',
                volume: 'Volumen',
                water_level: 'Wasserpegel',
            },
            types: {
                'air-quality': 'Luftqualität',
                'compressor-trailer': 'Kompressoranhänger',
                'default-gasser': 'Diverses',
                'material-container': 'Materialcontainer',
                'parking-space': 'Parkplatz',
                'sbb-bin': 'SBB Recycling Station',
                'sbb-bin-alu': 'SBB Recyclingstation Alu',
                'sbb-bin-paper': 'SBB Recyclingstation Papier',
                'sbb-bin-pet': 'SBB Recyclingstation PET',
                'sbb-bin-waste': 'SBB Recyclingstation Abfall',
                'skip-bin': 'Abfallmulde',
                'team-container': 'Mannschaftscontainer',
                'team-trailer': 'Mannschaftswagen',
                'waste-dump': 'Mulde',
                'wheel-loader': 'Radlader',
                Traktor: 'Traktor',
                Vespa: 'Vespa',
                bicycle: 'Fahrrad',
                bin: 'Abfalleimer',
                boat: 'Boot',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Kompressor',
                dumper: 'Dumper',
                excavator: 'Bagger',
                glasbock: 'Glasbock',
                horse: 'Pferd',
                hund: 'Hund',
                kuh: 'Kuh',
                lama: 'Lama',
                roller: 'Walze',
                schaf: 'Schaf',
                scooter: 'Scooter',
                temperature: 'Temperatur',
                trailer: 'Anhänger',
                truck: 'Lastwagen',
                ziege: 'Ziege',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Abbrechen',
            lastMeasurement: 'Letzte Messung',
            no: 'Nein',
            noOptions: 'Keine Optionen verfügbar',
            noSearchResult: 'Kein Suchresultat',
            save: 'Speichern',
            yes: 'Ja',
        },
        router: {
            accel_y: 'Leerung',
            statuschartview: 'Zustand',
            allAssets: 'Alle Assets',
            animalactivitychart: 'Aktivität',
            assetConnectionHeatmap: 'Verbindungsqualität',
            assetLocationHistoryChart: 'Standort Verlauf',
            assetScheme: 'Schema',
            battery: 'Batterie',
            battery_percentage: 'Ladezustand',
            battery_voltage: 'Batteriespannung',
            changePassword: 'Passwort ändern',
            chartsCombined: 'Kombinierter Graph',
            charts: 'Diagramme',
            cm2humiditychart: 'Temperatur und Feuchtigkeit',
            co2: 'Kohlenstoffdioxid',
            connectionHeatmap: 'Verbindungsqualität',
            createlocation: 'Neuer Standort',
            current: 'Silo',
            d1: 'Kanal 1',
            d2: 'Kanal 2',
            dashboardSettings: 'Einstellungen',
            detail: 'Details',
            distance: 'Distanz',
            dynamicbinchart: 'Füllstand',
            ecocoach_d1_count: 'Power up',
            ecocoach_temperature_0: 'Batterietemperatur',
            ecocoach_temperature_1: 'AFE Temperatur',
            ecocoach_voltage_0: 'Batteriespannung',
            editAsset: 'Asset bearbeiten',
            editlocation: 'Standort bearbeiten',
            fenceGuardVoltage: 'Spannung Weidezaun',
            fenceguardstatuschart: 'Spannung Weidezaun',
            fillLevel: 'Füllstand',
            fill_level: 'Füllstand',
            globalAnimalActivityChart: 'Aktivität',
            humidity: 'Luftfeuchtigkeit',
            level: 'Pegel',
            location: 'Standort',
            locationAnimalActivityChart: 'Aktivität',
            locationConnectionHeatmap: 'Verbindungsqualität',
            locationDetailAssetDetail: 'Details',
            locationHistoryChart: 'Standort Verlauf',
            locationhistory: 'Verlauf',
            triphistory: 'Fahrtenverlauf',
            lostAssets: 'Zone verlassen',
            lowBatteryAssets: 'Niedriger Batteriestand',
            lower_loop: 'Untere Schlaufe',
            mass: 'Masse',
            menu: 'Menü',
            navigation: 'Navigation',
            networkChart: 'Signal Qualität',
            newAsset: 'Neuer Tracker',
            notifications: 'Benachrichtigungen',
            potential: 'Potential',
            power: 'Total',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            runningTimeChart: 'Betriebsstunden',
            running_time: 'Betriebsstunden',
            sbbbinchart: 'Füllstand',
            search: 'Suche',
            settings: 'Einstellungen',
            signal: 'Signal',
            speed: 'Geschwindigkeit',
            t1: 'Vorlauftemperatur',
            t2: 'Rücklauftemperatur',
            t3: 'Raumtemperatur',
            t4: 'Aussentemperatur',
            t5: 't5',
            t6: 't6',
            tau: 'Tau',
            temperature: 'Temperatur',
            tracking: 'Tracking',
            upper_loop: 'Obere Schlaufe',
            voc: 'Flüchtige organische Verbindungen',
            voltage_0: 'Litze 1',
            voltage_1: 'Litze 2',
            voltage_2: 'Litze 3',
            voltage_3: 'Litze 4',
            voltage_4: 'Litze 5',
            volume: 'Volumen',
            water_level: 'Wasserpegel',
            moisture: 'Feuchtigkeit',
            treehistorymoisturechart: 'Feuchtigkeit',
            treehistorytemperaturechart: 'Temperatur',
        },
    },
    it: {
        shared: {
            measurementOptions: {
                battery_status: {
                    critical: 'Critical',
                    ok: 'Ok',
                    warning: 'Warning',
                },
            },
            measurements: {
                ai1: 'Ingresso analogico - Canale 1',
                battery: 'Batteria',
                battery_status: 'Battery status',
                battery_voltage: 'Tensione della batteria',
                co2: 'CO2',
                custom: 'Personalizzato',
                d1: 'Stato - Canale 1',
                d2: 'Stato - Canale 2',
                d3: 'Stato - Canale 3',
                d4: 'Stato - Canale 4',
                d5: 'Stato - Canale 5',
                d6: 'Stato - Canale 6',
                distance: 'Distanza',
                fill_level: 'Livello di riempimento',
                geofence: 'Geofence',
                humidity: 'Umidità',
                level: 'Livello',
                lower_loop: 'Lower loop',
                mass: 'Massa',
                moisture: 'Umidità',
                offline: 'Offline',
                potential: 'Potential',
                power: 'Power',
                probe_state: 'Stato campione',
                regex: 'Regex',
                running_time: 'Tempo di funzionamento',
                speed: 'Velocità',
                state: 'Stato',
                t0: 'Temperatura - Canale 0',
                t1: 'Temperatura - Canale 1',
                t2: 'Temperatura - Canale 2',
                t3: 'Temperatura - Canale 3',
                t4: 'Temperatura - Canale 4',
                t5: 'Temperatura - Canale 5',
                t6: 'Temperatura - Canale 6',
                tau: 'Tau',
                temperature: 'Temperatura',
                upper_loop: 'Upper loop',
                voc: 'VOC',
                voltage: 'Voltaggio Recinzione',
                voltage_0: 'Filo 1',
                voltage_1: 'Filo 2',
                voltage_2: 'Filo 3',
                voltage_3: 'Filo 4',
                voltage_4: 'Filo 5',
                volume: 'Volume',
                water_level: "Livello dell'acqua",
            },
            types: {
                'air-quality': 'Air quality',
                'compressor-trailer': 'Rimorchio a compressore',
                'default-gasser': 'Varie',
                'material-container': 'Contenitore di materiale',
                'parking-space': 'Parking space',
                'sbb-bin': 'SBB recycling station',
                'sbb-bin-alu':
                    "Stazione di riciclaggio dell'alluminio delle FFS",
                'sbb-bin-paper': 'Carta della stazione di riciclaggio FFS',
                'sbb-bin-pet': 'Stazione di riciclaggio FFS PET',
                'sbb-bin-waste': 'Rifiuti delle stazioni di riciclaggio FFS',
                'skip-bin': 'Skip bin',
                'team-container': 'Team-contenitore',
                'team-trailer': 'Team trailer',
                'waste-dump': 'Discarica',
                'wheel-loader': 'Caricatore a ruota',
                Traktor: 'Trattore',
                Vespa: 'Vespa',
                bicycle: 'Bicycle',
                bin: 'Bin',
                boat: 'Boat',
                bus: 'Bus',
                car: 'Auto',
                compressor: 'Compressore',
                dumper: 'Dumper',
                excavator: 'Excavator',
                glasbock: 'Glas pallet',
                horse: 'Horse',
                hund: 'Dog',
                kuh: 'Cow',
                lama: 'Lama',
                roller: 'Rullo',
                schaf: 'Sheep',
                scooter: 'Scooter',
                temperature: 'Temperature',
                trailer: 'Trailer',
                truck: 'Truck',
                ziege: 'Goat',
            },
            asset: 'Asset',
            assetCount: '{n} Asset | {n} Assets',
            assets: 'Assets',
            cancel: 'Staccare',
            lastMeasurement: 'Ultimo misurazione',
            no: 'No',
            noOptions: 'Nessuna opzione disponibile',
            noSearchResult: 'Nessun risultato di ricerca',
            save: 'Salvare',
            yes: 'Si',
        },
        router: {
            accel_y: 'Collezione',
            statuschartview: 'Stato',
            allAssets: 'Tutti Assets',
            animalactivitychart: 'Attività',
            assetConnectionHeatmap: 'Qualità della connessione',
            assetLocationHistoryChart: 'Storico Locazione',
            assetScheme: 'Schema',
            battery: 'Batteria',
            battery_percentage: 'State of Charge',
            battery_voltage: 'Tensione della batteria',
            changePassword: 'Modificare Password',
            chartsCombined: 'Grafico combinato',
            charts: 'Grafici',
            co2: 'Anidride carbonica',
            cm2humiditychart: 'Temperatura e umidità',
            connectionHeatmap: 'Qualità della connessione',
            createlocation: 'Nuovo Locazione',
            current: 'Silo',
            d1: 'Channel 1',
            d2: 'Channel 2',
            dashboardSettings: 'Impostazione',
            detail: 'Dettaglio',
            distance: 'Distanza',
            dynamicbinchart: 'Livello di riempimento',
            ecocoach_d1_count: 'Power up',
            ecocoach_temperature_0: 'Battery Temperatura',
            ecocoach_temperature_1: 'AFE Temperatura',
            ecocoach_voltage_0: 'Livello dell batteria',
            editAsset: 'Modificare Asset',
            editlocation: 'Modificare Locazione',
            fenceGuardVoltage: 'Voltaggio Recinzione',
            fenceguardstatuschart: 'Voltaggio Recinzione',
            fillLevel: 'Livello di riempimento',
            fill_level: 'Livello di riempimento',
            globalAnimalActivityChart: 'Attività',
            humidity: 'Umidità',
            level: 'Livello',
            location: 'Locazione',
            locationAnimalActivityChart: 'Attività',
            locationConnectionHeatmap: 'Qualità della connessione',
            locationDetailAssetDetail: 'Dettaglio',
            locationHistoryChart: 'Storico Locazione',
            locationhistory: 'Storico',
            triphistory: 'Percorso di viaggio',
            lostAssets: 'Lascia la zona',
            lowBatteryAssets: 'Basso Batteria',
            lower_loop: 'Lower loop',
            mass: 'Massa',
            menu: 'Menu',
            navigation: 'Navigation',
            networkChart: 'Qualità del segnale',
            newAsset: 'Nuovo Asset',
            notifications: 'Notificazione',
            potential: 'Potential',
            power: 'Totalmente',
            power_0: 'L0',
            power_1: 'L1',
            power_2: 'L2',
            power_3: 'L3',
            runningTimeChart: 'Tempo di Esecuzione',
            running_time: 'Tempo di Esecuzione',
            sbbbinchart: 'Livello di riempimento',
            search: 'Ricerca',
            settings: 'Impostazione',
            signal: 'Signal',
            speed: 'Velocità',
            t1: 'Temperatura di mandata',
            t2: 'Temperatura di ritorno',
            t3: 'Temperatura ambiente',
            t4: 'Temperatura esterna',
            t5: 't5',
            t6: 't6',
            tau: 'Tau',
            temperature: 'Temperatura',
            tracking: 'Tracking',
            upper_loop: 'Upper loop',
            voc: 'Composti organici volatili',
            voltage_0: 'Filo 1',
            voltage_1: 'Filo 2',
            voltage_2: 'Filo 3',
            voltage_3: 'Filo 4',
            voltage_4: 'Filo 5',
            volume: 'Volume',
            water_level: "Livello dell'acqua",
            moisture: 'Umidità',
            treehistorymoisturechart: 'Umidità',
            treehistorytemperaturechart: 'Temperatura',
        },
    },
}
