var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-search"},[_c('base-input',{ref:"input",attrs:{"placeholder":_vm.$t('searchPlaceholder')},on:{"blur":function($event){_vm.isDropdownVisible = false},"focus":function($event){_vm.isDropdownVisible = true},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.handleOptionNavigation(-1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.handleOptionNavigation(1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.handleOptionSelect()}]},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('search-icon',{attrs:{"width":"16","height":"16","color":"#acbac7"}})]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.isDropdownVisible)?_c('div',{staticClass:"map-search__dropdown",on:{"mousedown":function($event){$event.preventDefault();}}},[(!_vm.query)?_c('p',{staticClass:"l-padded"},[_vm._v(" "+_vm._s(_vm.$t('searchHint'))+" ")]):(
                    !_vm.filteredAssets.length &&
                        !_vm.filteredLocations.length &&
                        !_vm.places.length
                )?_c('p',{staticClass:"l-padded"},[_vm._v(" "+_vm._s(_vm.$t('searchEmpty'))+" ")]):_vm._e(),(_vm.filteredAssetsPortion.length)?_c('p',{staticClass:"l-padded"},[_vm._v(" "+_vm._s(_vm.$t('shared.assets'))+" ")]):_vm._e(),_vm._l((_vm.filteredAssetsPortion),function(item,i){return _c('a',{key:'asset' + item.id,class:{ hover: i === _vm.hoverIndex },on:{"click":function($event){return _vm.handleAssetClick(item)}}},[_c('span',[_c('pin-icon',{attrs:{"width":"16","height":"16"}})],1),_vm._v(" "+_vm._s(item.asset_details.name)+" ")])}),(
                    _vm.filteredAssets.length &&
                        _vm.filteredAssets.length > _vm.assetsLimit
                )?_c('a',{staticClass:"t-small",on:{"click":function($event){_vm.assetsLimit += _vm.limitStep}}},[_vm._v(" "+_vm._s(_vm.$t('showMoreAssets', { items: _vm.$t('shared.assets'), }))+" ")]):_vm._e(),(_vm.filteredLocationsPortion.length)?_c('p',{staticClass:"l-padded"},[_vm._v(" "+_vm._s(_vm.$t('locations'))+" ")]):_vm._e(),_vm._l((_vm.filteredLocationsPortion),function(item,i){return _c('a',{key:'location' + item.id,class:{
                    hover: _vm.filteredAssetsPortion.length + i === _vm.hoverIndex,
                },on:{"click":function($event){return _vm.handleLocationClick(item)}}},[_c('span',[_c('pin-location-icon',{attrs:{"width":"16","height":"16"}})],1),_vm._v(" "+_vm._s(item.name)+" ")])}),(
                    _vm.filteredLocations.length &&
                        _vm.filteredLocations.length > _vm.locationsLimit
                )?_c('a',{staticClass:"t-small",on:{"click":function($event){_vm.locationsLimit += _vm.limitStep}}},[_vm._v(" "+_vm._s(_vm.$t('showMoreLocations'))+" ")]):_vm._e(),(_vm.placesPortion.length)?_c('p',{staticClass:"l-padded"},[_vm._v(" "+_vm._s(_vm.$t('places'))+" ")]):_vm._e(),_vm._l((_vm.placesPortion),function(item,i){return _c('a',{key:'place' + item.id,class:{
                    hover:
                        _vm.filteredAssetsPortion.length +
                            _vm.filteredLocationsPortion.length +
                            i ===
                        _vm.hoverIndex,
                },on:{"click":function($event){return _vm.handlePlaceClick(item)}}},[_c('span',[_c('map-icon',{attrs:{"width":"16","height":"16"}})],1),_vm._v(" "+_vm._s(item.place_name)+" ")])}),(_vm.places.length && _vm.places.length > _vm.placesLimit)?_c('a',{staticClass:"t-small",on:{"click":function($event){_vm.placesLimit += _vm.limitStep}}},[_vm._v(" "+_vm._s(_vm.$t('showMorePlaces'))+" ")]):_vm._e()],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }