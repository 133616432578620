import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import common from './modules/common'
import createLocation from './modules/createLocation'
import dashboard from './modules/dashboard'
import demo from './modules/demo'
import locations from './modules/locations'
import map from './modules/map'
import tracker from './modules/tracker'
import user from './modules/user'
import trips from './modules/trips'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        authentication,
        common,
        createLocation,
        dashboard,
        demo,
        locations,
        map,
        tracker,
        user,
        trips,
    },
})
