<template>
    <div class="map-controls" :class="{ 'map-controls--mobile': mobile }">
        <!-- TEMP: -->
        <div></div>

        <div>
            <button
                :class="{ active: positionTrackingEnabled }"
                @click="toggleLocationTracking"
            >
                <location-target-icon :width="iconSize" :height="iconSize" />
            </button>

            <map-search v-if="!mobile" />
        </div>

        <div>
            <button @click="openDropdown">
                <settings-slider-icon :width="iconSize" :height="iconSize" />

                <transition name="dropdown">
                    <div
                        v-if="isDropdownVisible"
                        class="map-controls__dropdown"
                        @click="$event.stopPropagation()"
                    >
                        <h3 class="t-title">
                            {{ $t('displayOptions') }}
                        </h3>

                        <div class="l-stack l-gap-1">
                            <label class="l-inline l-center-v l-gap-1">
                                <toggle-button
                                    class="redesigned"
                                    :value="clusteringEnabled"
                                    sync
                                    css-colors
                                    :width="40"
                                    :height="24"
                                    :speed="150"
                                    @input="switchClusteringEnabled"
                                />

                                <span>{{
                                    $t('displayOptionsClustering')
                                }}</span>
                            </label>

                            <label class="l-inline l-center-v l-gap-1">
                                <toggle-button
                                    class="redesigned"
                                    :value="showLabels"
                                    sync
                                    css-colors
                                    :width="40"
                                    :height="24"
                                    :speed="150"
                                    @input="switchShowLabels"
                                />

                                <span>{{ $t('displayOptionsLabels') }}</span>
                            </label>
                        </div>

                        <h3 class="t-title">
                            {{ $t('layerOptions') }}
                        </h3>

                        <div class="l-stack l-gap-1">
                            <label
                                v-for="provider in tileProviders"
                                :key="provider.id"
                                class="l-inline l-center-v l-gap-1"
                            >
                                <v-radio
                                    :checked="provider.id === activeLayer.id"
                                    :value="provider.id"
                                    @input="handleTileProviderChange(provider)"
                                />

                                <span>{{
                                    $t(`layerOptions-${provider.id}`)
                                }}</span>
                            </label>
                        </div>
                    </div>
                </transition>
            </button>

            <div v-if="!mobile" class="button-group">
                <button
                    :disabled="!mapInstance || isZoomOutDisabled"
                    @click="mapInstance.zoomOut()"
                >
                    –
                </button>

                <button
                    :disabled="!mapInstance || isZoomInDisabled"
                    @click="mapInstance.zoomIn()"
                >
                    +
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'
import { Radio } from 'vue-checkbox-radio'

import LocationTargetIcon from '../icons/LocationTargetIcon'
import MapSearch from './MapSearch'
import SettingsSliderIcon from '../icons/SettingsSliderFramedIcon'

export default {
    name: 'MapControls',
    components: {
        LocationTargetIcon,
        MapSearch,
        SettingsSliderIcon,
        ToggleButton,
        VRadio: Radio,
    },
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDropdownVisible: false,
        }
    },
    computed: {
        ...mapState('map', [
            'activeLayer',
            'clusteringEnabled',
            'mapInstance',
            'positionTrackingEnabled',
            'showLabels',
            'tileProviders',
        ]),
        isZoomInDisabled() {
            return this.mapInstance.getZoom() >= this.mapInstance.getMaxZoom()
        },
        isZoomOutDisabled() {
            return this.mapInstance.getZoom() <= this.mapInstance.getMinZoom()
        },
        iconSize() {
            return this.mobile ? 24 : 18
        },
    },
    methods: {
        ...mapMutations('map', [
            'setActiveLayer',
            'switchShowLabels',
            'switchClusteringEnabled',
            'setPositionTrackingEnabled',
        ]),
        openDropdown() {
            if (this.isDropdownVisible) {
                return
            }

            this.isDropdownVisible = true

            const listener = () => {
                document.body.removeEventListener('click', listener)
                this.isDropdownVisible = false
            }

            setTimeout(() => {
                document.body.addEventListener('click', listener)
            })
        },
        handleTileProviderChange(provider) {
            if (this.activeLayer.layer) {
                this.mapInstance.removeLayer(this.activeLayer.layer)
            }

            this.mapInstance.addLayer(provider.layer)
            this.setActiveLayer(provider)
        },
        toggleLocationTracking() {
            if (!this.positionTrackingEnabled) {
                this.mapInstance.locate({ watch: true, setView: true })
                this.setPositionTrackingEnabled(true)
            } else {
                this.mapInstance.stopLocate()
                this.setPositionTrackingEnabled(false)
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "displayOptions": "Display",
        "displayOptionsClustering": "Clustering",
        "displayOptionsLabels": "Beschriftungen",
        "layerOptions": "Layer",
        "layerOptions-outdoor": "Terrain map",
        "layerOptions-satellite": "Satellite map",
        "layerOptions-standard": "Standard map",
        "layerOptions-street": "Street map",
        "layerOptions-swisstopo": "Swisstopo map",
        "layerOptions-vectormap": "Light map"
    },
    "de": {
        "displayOptions": "Anzeigeoptionen",
        "displayOptionsClustering": "Gruppieren",
        "displayOptionsLabels": "Beschriftungen",
        "layerOptions": "Karte",
        "layerOptions-outdoor": "Geländekarte",
        "layerOptions-satellite": "Satellitenkarte",
        "layerOptions-standard": "Standardkarte",
        "layerOptions-street": "Strassenkarte",
        "layerOptions-swisstopo": "Swisstopo Karte",
        "layerOptions-vectormap": "Light map"
    },
    "it": {
        "displayOptions": "Display",
        "displayOptionsClustering": "Clustering",
        "displayOptionsLabels": "Beschriftungen",
        "layerOptions": "Layer",
        "layerOptions-outdoor": "Mappa dei terreni",
        "layerOptions-satellite": "Mappa satellitare",
        "layerOptions-standard": "Mappa standard",
        "layerOptions-street": "Mappa stradale",
        "layerOptions-swisstopo": "Swisstopo Map",
        "layerOptions-vectormap": "Lightstre map"
    }
}
</i18n>

<style lang="scss" scoped>
.map-controls {
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;

        &:not(:first-child) {
            margin-left: 8px;
        }

        &:nth-child(2) {
            flex-basis: 30%;
        }

        & > * {
            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        // TEMP:
        pointer-events: auto;
    }

    &--mobile {
        justify-content: flex-end;

        button {
            margin-left: 1rem;
            padding: 1rem 0;
            background-color: transparent;
            box-shadow: none;
            color: $color-primary-contrast;

            &.active:not(:hover) {
                color: $color-primary-contrast;
            }

            &:hover {
                color: $color-primary-contrast;
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        margin: 6px 0 12px;
        padding: 1rem;
        background-color: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $color-primary;
        text-align: left;
        z-index: 100;
        cursor: default;

        h3 {
            margin-bottom: 1rem;
            font-weight: 700;
            font-size: 14px;

            &:not(:first-child) {
                margin-top: 1.25rem;
            }
        }

        label {
            cursor: pointer;
        }
    }
}

button {
    position: relative;
    display: inline-block;
    padding: 10px 16px;
    background-color: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    line-height: 20px;
    font-family: 'IBM Plex Sans';
    font-weight: 300;
    font-size: 24px;
    color: $color-primary-lighter;
    transition: all 0.1s;
    outline: none;
    cursor: pointer;

    &.active:not(:hover) {
        color: darken($color-primary-lighter, 30%);
    }

    &:hover {
        color: darken($color-primary-lighter, 15%);
    }

    &:disabled {
        color: rgba($color-primary-lighter, 0.5);
        background-color: rgba(255, 255, 255, 0.5);
        cursor: default;
    }
}

.button-group {
    display: inline-block;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    button {
        border-radius: 0;
        box-shadow: none;

        &:not(:first-child) {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.dropdown {
    &-enter-active,
    &-leave-active {
        transition: all 0.1s ease-out;
    }

    &-enter,
    &-leave-to {
        transform: translateY(-8px);
        opacity: 0;
    }
}
</style>
